<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <div class="splitTitle">{{ $t('logistics.投保信息') }}</div>
      <van-collapse-item :title="$t('logistics.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('logistics.账套') }}</h5>
          <p>{{ ruleForm.orgName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.投保单号') }}</h5>
          <p>{{ ruleForm.insureNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.业务类型') }}</h5>
          <p>{{ ruleForm.businessType | setDict('booking_business_type') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.预投保日期') }}</h5>
          <p>{{ ruleForm.insureDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.是否我司订船') }}</h5>
          <p>{{ ruleForm.insureDate | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.保险公司') }}</h5>
          <p>{{ ruleForm.insuranceCompany }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.框架协议') }}</h5>
          <p>{{ ruleForm.frameworkNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.投保币种') }}</h5>
          <p>{{ ruleForm.frameworkNo | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.对人民币汇率') }}</h5>
          <p>{{ ruleForm.baseCurrency }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.投保险别') }}</h5>
          <p>{{ ruleForm.insuranceType | setDict('INSURANCE_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.原币币种') }}</h5>
          <p>{{ ruleForm.originalCurrency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.原币金额') }}</h5>
          <p>{{ getOriginaAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.保额') }}</h5>
          <p>{{ ruleForm.insuredAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.免赔额') }}/率</h5>
          <p>{{ ruleForm.deductibleRate ? ruleForm.deductibleRate:'0' }}%</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.费率') }}</h5>
          <p>{{ ruleForm.rate ? ruleForm.rate:'0' }}%</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.保费') }}</h5>
          <p>{{ getPremium }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.投保人') }}</h5>
          <p>{{ ruleForm.policyHolderName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.被保险人') }}</h5>
          <p>{{ ruleForm.insuredName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.赔付地点') }}</h5>
          <p>{{ ruleForm.compensationPlace }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.包装及数量') }}</h5>
          <p>{{ ruleForm.packagingQuantity | formatAmount(6,false) }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.运输方式') }}</h5>
          <p>{{ ruleForm.shippingType | setDict('MODE_TRANSPORT') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.状态') }}</h5>
          <p>{{ ruleForm.status | setDict('PUB_WF_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.起运日期') }}</h5>
          <p>{{ ruleForm.shipmentDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.航次') }}/航班/车号</h5>
          <p>{{ ruleForm.vftNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.是否结算') }}</h5>
          <p>{{ ruleForm.isSettlement | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.备注') }}</h5>
          <p>{{ ruleForm.remarks }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('logistics.保险登记信息')" name="2" v-if="ruleForm.status === 'APPROVED' && (this.$route.query.action ==='register' || ruleForm.isRegister==='Y') ">
        <div class="items">
          <h5>{{ $t('logistics.实际保单号') }}</h5>
          <p>{{ ruleForm.actualPolicyNumber }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.实际投保日期') }}</h5>
          <p>{{ ruleForm.actualPolicyDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.实际保费') }}</h5>
          <p>{{ ruleForm.actualPremium }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('logistics.关联单据信息')" name="3">
        <div v-if="!ruleForm.logisticsInsureDetailList || !ruleForm.logisticsInsureDetailList.length">{{ $t('logistics.无') }}</div>
        <div v-for="(item, index) in this.ruleForm.logisticsInsureDetailList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('logistics.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.单据类型') }}</h5>
            <p>{{ item.documentType | setDict('RELATION_BILL') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.单据号') }}</h5>
            <p>{{ item.documentNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.币别') }}</h5>
            <p>{{ item.currency | setDict('CURRENCY_TYPE') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.汇率') }}</h5>
            <p>{{ item.rate }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.原币金额') }}</h5>
            <p>{{ item.originalAmount | formatAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.本位币金额') }}</h5>
            <p>{{ item.baseAmount | formatAmount }}</p>
          </div>
          <div class="items">
            <h5 v-if="documentType==='1'">{{ $t('logistics.运费') }}(合同币种)</h5>
            <p>{{ item.freightContractAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.保险系数') }}</h5>
            <p>{{ item.insuranceFactor ? item.insuranceFactor+'%':'' }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.保额') }}</h5>
            <p>{{ item.insuredAmount | formatAmount }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('logistics.附件') }}</div>
      <van-collapse-item :title="$t('logistics.附件信息')" name="4">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('logistics.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('logistics.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('logistics.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('logistics.系统信息') }}</div>
      <van-collapse-item :title="$t('logistics.系统信息')" name="6">
        <div class="items">
          <h5>{{ $t('logistics.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('logistics.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import util from '../../../libs/util';
import myHistoryPanel from '@/views/business/components/myHistoryPanel';
import myApproval from '@/views/business/components/myApproval';

export default {
  name: 'visitorFieldComponent',
  components: {
    myHistoryPanel,
    myApproval
  },
  data() {
    return {
      documentType: '',
      attachmentList: [],
      loading: false,
      finished: false,
      activeNames: '1',
      hostName: '',
      task: '',
      personId: '',
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      id: '',
      key: '',
      title: '',
      detail: null,
      active: 0,
      businessKey: '',
      woCode: 'insureApplication',
      processInstanceName: '投保申请',
      mappingId: '',
      taskInstanceId: '',
      currentNode: '',
      variables: {},
      taskType: '',
      ruleForm: {
        id: '',
        orgName: '',
        status: 'PREPARING',
        insureNo: '',
        originalInsureNo: '',
        businessType: '',
        insureDate: '',
        isBookShip: '',
        insuranceCompany: '',
        frameworkNo: '',
        insuranceCurrency: '',
        baseCurrency: '1',
        insuranceType: '',
        originalCurrency: '',
        originaAmount: '',
        insuredAmount: '',
        deductibleRate: '',
        rate: '',
        premium: '',
        policyHolderName: '',
        insuredName: '',
        compensationPlace: '',
        packagingQuantity: '',
        shippingType: '',
        shipmentDate: '',
        vftNo: '',
        isSettlement: 'N',
        remarks: '',
        actualPolicyNumber: '',
        actualPolicyDate: '',
        actualPremium: '',
        // 关联投保信息
        logisticsInsureDetailList: []

      },
    };
  },
  computed: {
    getInsuredAmount (row, index) {
      return function (row, index) {
        let insuredAmount = 0;
        let freightAmount = 0;
        let originalAmount = 0;
        freightAmount = this.ruleForm.logisticsInsureDetailList[index].freightContractAmount ? this.ruleForm.logisticsInsureDetailList[index].freightContractAmount : 0;
        originalAmount = this.ruleForm.logisticsInsureDetailList[index].originalAmount ? this.ruleForm.logisticsInsureDetailList[index].originalAmount : 0;
        insuredAmount = (freightAmount + originalAmount) * this.ruleForm.logisticsInsureDetailList[index].insuranceFactor / 100;
        this.ruleForm.logisticsInsureDetailList[index].insuredAmount = insuredAmount || 0;
        return insuredAmount;
      };
    },
    // 汇总原币金额
    getOriginaAmount () {
      let originaAmount = 0;
      // 保额
      let insuredAmount = 0;
      this.ruleForm.logisticsInsureDetailList.forEach(res => {
        originaAmount += Number((Number(res.originalAmount) || 0).toFixed(2));
        insuredAmount += Number((Number(res.insuredAmount) || 0).toFixed(2));
      });
      this.ruleForm.originaAmount = originaAmount;
      this.ruleForm.insuredAmount = insuredAmount;
      return originaAmount;
    },
    // 计算保费
    getPremium () {
      let premium = 0;
      premium = Number((Number(this.ruleForm.insuredAmount * this.ruleForm.rate / 100) || 0).toFixed(2));
      this.ruleForm.premium = premium;
      return premium;
    }
  },
  props: {},
  methods: {
    /**
     * 根据文件id和文件名生成预览链接
     * @param id 附件id
     * @param fileName 附件名
     * @returns {string}
     */
    handleFileView (id, fileName) {
      this.$FilePrvwUtils.openFile(id,fileName);
    },
    downloadAttachment(id, fileName) {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/download",
        data: {
          "entity": {
            "id": id
          }
        },
        responseType: "arraybuffer"
      }).then(res => {
        // 成功回调
        var headers = res.headers;
        var blob = new Blob([res.data], {
          type: headers["content-type"]
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
    },
    getAttachmentList() {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/list",
        data: {
          "entity": {
            "bizId": this.ruleForm.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.attachmentList = backData;
        }
      });
    },
    getDocumentType () {
      if (this.ruleForm.isBookShip === 'Y') {
        // 订舱通知
        this.documentType = '1';
      } else {
        if (this.ruleForm.businessType === '1') {
          // 销售发货
          this.documentType = '3';
        }
        if (this.ruleForm.businessType === '2') {
          // 进口到单
          this.documentType = '2';
        }
      }
    },
    onLoad() {
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.ruleForm.importFeePaymentDetailList.push(this.ruleForm.importFeePaymentDetailList.length + 1);
        }
        this.loading = false;
        if (this.ruleForm.importFeePaymentDetailList.length >= 40) {
          this.finished = true;
        }
      }, 500);
    },
    getProcessMappingId() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
        data: {
          entity: {
            woCode: this.woCode,
            orgId: userInfo.companyId,
            deptId: userInfo.deptId
          }
        }
      }).then(res => {
        if (res.data.ext.code === '2000') {
          this.mappingId = res.data.ext.mappingId;
        } else {
          Toast.fail({
            message: res.data.ext.message,
            type: 'error',
            duration: 1500
          });
        }
      });
    },
    backfilleditData(id) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/pur/logistics/insureApplication/view',
        data: {
          entity: {
            id: id
          }
        }
      }).then(res => {
        // 成功回调方法
        var data = res.data.entity;
        if (data) {
          this.ruleForm = res.data.entity;
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.paymentApplicationNo, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.getAttachmentList();
          this.getDocumentType();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        }
      });
    },
    setApprovalData(row) {
      if (row && row.pid) {
        this.ruleForm.id = row.businessKey;
        this.taskInstanceId = row.id;
        this.taskType = row.taskType;
      }
    },
    /**
     * 业务提交之前校验
     * */
    checkBeforeProcess(type) {
      if (type) {
        if (type === 'beforeAddsign') {
          this.$refs.demoTemperatureEdit.beforeAddsign();
        } else if (type === 'afterAddsign') {
          this.$refs.demoTemperatureEdit.afterAddsign();
        } else if (type === 'endProcess') {
          this.$refs.demoTemperatureEdit.endProcess();
        } else if (type === 'repelProcess') {
          this.repelProcess();
        } else {
          if (!this.taskInstanceId) {
            var params = {
              businessKey: this.ruleForm.id,
              mappingId: this.mappingId,
              processInstanceName: this.processInstanceName,
              userName: sessionStorage.getItem('userName'),
              variables: this.variables
            };
            this.startProcessAction(params);
          } else {
            this.$refs.demoTemperatureEdit.submit();
          }
        }
      } else {
        Toast.fail({
          message: this.$t('logistics.数据校验失败'),
          type: 'warning'
        });
      }
    },
    // 业务发起流程
    startProcessAction(params) {
      if (params.mappingId) {
        params.mappingId = this.mappingId;
      }
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.paymentApplicationNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/pur/logistics/insureApplication/start',
        data: params
      })
        .then(res => {
          var backData = res.data.ext;
          if (backData) {
            this.$refs.demoTemperatureEdit.submitLoading = false;
            this.$refs.demoTemperatureEdit.isStart = true;
            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
            this.$refs.demoTemperatureEdit.isFirstNode = true;
            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
          } else {
            Toast.fail({
              message: this.$t('logistics.流程发起异常'),
              type: 'warning'
            });
            this.$refs.demoTemperatureEdit.submitLoading = false;
          }
        })
        .catch(_ => {
          this.$refs.demoTemperatureEdit.submitLoading = false;
        });
    },
    // 业务提交流程
    submitProcessAction(params) {
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.paymentApplicationNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/pur/logistics/insureApplication/submit',
        data: params
      })
        .then(res => {
          if (res.data.ext.success) {
            this.processCallback(null, null, this.taskType);
          } else {
            Toast.fail({
              message: res.data.ext.msg,
              type: 'warning'
            });
          }
        })
        .catch(_ => {});
    },
    /***
     * 业务撤销流程
     */
    repelProcess() {
      var _this = this;
      _this
        .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
          cancelButtonClass: 'sysBackBtn'
        })
        .then(_ => {
          this.$myHttp({
            method: 'post',
            url: '/microarch/pur/logistics/insureApplication/undoProcess',
            data: {
              entity: {
                id: this.ruleForm.id,
                processInstanceId: this.ruleForm.processInstanceId
              }
            }
          })
            .then(res => {
              if (res.data.success === undefined || res.data.success) {
                Toast.fail({
                  message: this.$t('logistics.撤销成功'),
                  type: 'success'
                });
                this.processCallback(null, null, this.taskType);
              } else {
                Toast.fail({
                  message: res.data.msg,
                  type: 'warning'
                });
              }
            })
            .catch(_ => {});
        })
        .catch(_ => {});
    },

    /***
     * 关闭发起流程审批窗口
     * 删除流程实例，回滚业务状态
     */
    closeStartCallback(pid) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/pur/logistics/insureApplication/deleteProcess',
        data: {
          entity: {
            id: this.ruleForm.id,
            processInstanceId: pid
          }
        }
      })
        .then(res => {
          this.$refs.demoTemperatureEdit.dialogVisible = false;
          this.$refs.demoTemperatureEdit.taskId = '';
          this.$refs.demoTemperatureEdit.processInstanceId = '';
          this.processCallback(null, null, this.taskType);
        })
        .catch(_ => {});
    },

    /**
     * 提交之前校验
     * */
    beforeSubmitCallBack(params) {
      // 业务参数校验 此时可以从params中获取提交审批所有的参数
      // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
      // 不通过给出相应的提示或者触发其他业务操作
      // let selectUserArray = params.candidateUserIds[params.nextId]
      // console.log(selectUserArray);
      this.submitProcessAction(params);
    },
    /**
     * 流程审批之后业务数据更改
     * */
    processCallback(piid, optionType, taskType) {
      var _this = this;
      // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
      // // 成功回调方法
      // if (taskType === 'transated') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/haveList'
      //   });
      // } else if (taskType === 'waitfor') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/toDoList'
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'sale/return/saleReturnApplyList'
      //   });
      //   this.$bus.emit('saleReturnApply-SaveSuccess');
      // }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    let selectRow = JSON.parse(this.$route.query.row);
    if (selectRow) {
      this.ruleForm.id = selectRow.id;
      if (selectRow.businessKey) {
        this.ruleForm.id = selectRow.businessKey;
      }
    }
    if (this.ruleForm.id) {
      this.backfilleditData(this.ruleForm.id);
    }

    if (selectRow) {
      this.setApprovalData(selectRow);
    }
  },
  filters: {
    setDict(v, dictName) {
      return util.setDict(v, dictName);
    },
    formatAmount(v, decimal = 2, isCovering = true) {
      return util.formatAmount(v, decimal, isCovering);
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;

  .left-col {
    float: left;
    width: 60%;
  }

  .img {
    float: right;
  }
}

.visitor-details {
  p {
    color: #aab2bd;
  }

  h5 {
    font-weight: bold;
  }
}

.img {
  width: 40%;
  text-align: center;
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.panel-list {
  .panel {
    margin-top: 5px;
    background: #fff;
  }

  .divline {
    padding: 0 0 5px 0;
  }

  .icon-status {
    width: 50px;
    height: 45px;
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 36px;
    right: 5px;
  }

  .type-0 {
    background-image: url('../../../images/icon-queryStatus-0.png');
  }

  .type-1 {
    background-image: url('../../../images/icon-queryStatus-1.png');
  }

  .type-2 {
    background-image: url('../../../images/icon-queryStatus-2.png');
  }

  .type-3 {
    background-image: url('../../../images/icon-queryStatus-3.png');
  }

  .type-4 {
    background-image: url('../../../images/icon-queryStatus-4.png');
  }
}

.table {
  margin-top: 5px;

  td {
    background: #f6f6f6;
    height: auto;
    padding: 8px 5px;
    border-bottom: 5px solid #fff;
  }
}

.personal-table {
  td {
    text-align: left;
    word-break: break-all;
  }

  img {
    vertical-align: middle;
  }

  .idcard {
    font-size: 12px;
    padding: 0;
  }
}

.car-table {
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.visit-record {
  padding: 2px 0 0;

  ul {
    li {
      padding: 3px 0;
    }
  }
}
</style>
